import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerRemitos() {
    var url = ConfigAPI.baseURL + "remits" + Session.getToken();
    return instance.get(url);
  },
  mostrarRemito(id) {
    var url = ConfigAPI.baseURL + "remits/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarRemito(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "remits" + Session.getToken();
    return instance.post(url,params);
  },
  editarRemito(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "remits/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarRemito(id) {
    var url = ConfigAPI.baseURL + "remits/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  filtrarRemitos(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "remits/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  filtrarPendienteRemitir(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "remits/filter/pendingRemit" + Session.getToken();
    return instance.post(url,params);
  },    
  filtrarVoucherPendienteRemitir(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "remits/filter/vouchersPendingRemit" + Session.getToken();
    return instance.post(url,params);
  }, 

  obtenerVoucherById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/id" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerVoucherByCustomer(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/customer" + Session.getToken();
    return instance.post(url,params);
  },      
  
  obtenerZona() {
    var url = ConfigAPI.baseURL + "sellers-zone" + Session.getToken();
    return instance.get(url);
  },      
  obtenerPuntosVentas() {
    var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
    return instance.get(url);
  }, 
  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },    

  mostrarPedido(id) {
    var url = ConfigAPI.baseURL + "orders/" + id + Session.getToken();
    return instance.get(url);
  },    
}

export default servicesAPI;
